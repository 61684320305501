.stage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #fff;
    box-shadow: 0 0 5px 0 #ccc;
    margin: 5px;
    min-width: 360px;
}
.stage.ghost {
    background-color: transparent;
    box-shadow: none;
    margin: 0;
}
.stage > article,
.stage > section,
.stage > div {
    margin: 16px 20px;
}

