@import "~antd/dist/antd.less";

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
}
html, body, #app {
    height: 100%;
}
.ant-layout-footer,
.ant-layout {
    background-color: #efeff1;
}
#app h1,
#app h2,
#app h3,
#app h4,
#app h5,
#app h6,
#app .ant-page-header-heading-title {
    color: rgba(0, 0, 0, 0.65);
}
#app h2,
#app .ant-page-header-heading-title {
    font-weight: 500;
    font-size: 20px;
}

/******************/
/* success button */
.ant-btn.success {
    border-color: #0c0;
    color: #0c0;
    background-color: #fff;
}
.ant-btn.success:hover {
    border-color: #0c0;
    color: #fff;
    background-color: #0c0;
}
/******************/
button.flat.ant-btn-clicked:after {
    border: none;
}

/*******************/
/* loading spinner */
.ant-spin-nested-loading.inline .ant-spin-container {
    display: inline;
}

/******************/
/* forms */
.ant-modal-body .ant-form-item {
    margin-bottom: 8px;
}
.ant-input-group-addon .ant-btn {
    margin-left: -11px;
    margin-right: -11px;
    padding-left: 11px;
    padding-right: 11px;
}

/******************/
/* suppress error appearance of form fields */
.has-error .suppress-error .ant-select-selection {
    border-color: #d9d9d9;
}
.has-error .suppress-error .ant-select-arrow {
    color: rgba(0, 0, 0, 0.25);
}

/******************/
/* tooltip */
.ant-tooltip {
    z-index: 10000;
}
.ant-tooltip-arrow::before,
.ant-tooltip-inner {
    background-color: #192037;
}

/******************/
/* drawer */
.ant-drawer-title {
    padding-right: 16px;
}

/******************/
/* alert */
.ant-message .multiline .ant-message-custom-content {
  display: flex;
  text-align: left;
}
.ant-message .multiline .ant-message-custom-content span[role=img] {
  align-self: center;
  margin-right: 15px;
}

@blue-base: #00b0f0;@red-base: #e33;@green-base: #2fcc7b;@gold-base: #faad14;