.footerNav {
    text-align: center;
}
ul.footerNav {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footerNav li,
.footerNav a {
    display: inline;
    color: #00b0f0;
}
.footerNav .delimiter {
    font-size: 0.6rem;
    padding: 0 0.5rem;
    position: relative;
    top: -0.08rem;
    color: #00b0f0;
}
