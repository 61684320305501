.ant-btn.inline {
    border: none;
    display: inline;
    padding: 0;
    margin: 0;
    color: #00b0f0;
    height: auto;
    width: auto;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    box-shadow: none;
}
