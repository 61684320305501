.c3 text {
    fill: #192037;
    font-size: 12px;
}

.c3 line, .c3 path {
    stroke: #192037;
}

.c3-target-dashed {
    stroke-dasharray: 2, 2;
}

/*.c3-region rect {*/
/*fill-opacity: 1 !important;*/
/*}*/
.c3-region.region-credit-line {
    fill: rgb(155, 160, 165);
}

.c3-region.region-negative {
    fill: rgb(255, 0, 0);
}

.c3-region.region-fixed {
    fill: rgba(55, 68, 102, 0.8);
}

.c3-region.region-unfixed {
    fill: rgba(68, 102, 153, 0.6);
}


.c3-chart-line .c3-line {
    stroke-width: 1.25px;
    /*stroke: #108ee9 !important;*/
}

/* grid lines */
.c3-xgrids line, .c3-ygrids line {
    stroke: #dfdfdf;
}

.c3-xgrid-focus line {
    stroke: #dfdfdf;
}

/* custom grid lines */
/*.c3-xgrid-line line {*/
/*stroke: rgba(255, 0, 0, 0);*/
/*}*/
.c3-xgrid-line line {
    opacity: 0.6 !important;
    pointer-events: none;
    stroke: #192037 !important;
}

.c3-xgrid-line text {
    opacity: 0.6 !important;
    pointer-events: none;
    fill: #192037 !important;
}

/* category ticks for timeline */
.c3-axis-x .tick:nth-child(2n+1) line {
    display: none;
}

/* tooltips */
.c3-tooltip {
    opacity: 1 !important;
}

.c3-tooltip th {
    background-color: #00B0F0 !important;
    white-space: pre-wrap;
}

.c3-tooltip tr {
    color: #192037 !important;
}

.c3-tooltip td {
    color: #192037 !important;
    border-width: 0 !important;
}
